<template>
  <div class="users">
    <Header title="用户管理" index="首页" beforeTitle="用户管理" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            clearable
            placeholder="请选择部门"
            v-model="listParams.organization_id"
            @change="getitemId"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            clearable
            v-model="listParams.keywords"
            placeholder="请输入姓名或者手机号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
          <el-button v-if="dataList.raffle == 1" class="add" @click="smoke()">抽奖</el-button>
        </div>
        <el-button v-if="privilige_list.member.includes('add')" class="add" @click="addUser('')">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="dataList.data"
        style="width: 100%"
      >
        <el-table-column prop="avatar" label="头像" width="130">
          <template slot-scope="scope">
            <div>
              <el-avatar icon="el-icon-user-solid" :src="userSite + scope.row.avatar"></el-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="true_name" label="真实姓名"></el-table-column>
        <!-- 
				<el-table-column prop="nick_name" label="昵称" width="100">
        </el-table-column>-->
        <el-table-column prop="tel" label="手机号/账号"></el-table-column>
        <el-table-column prop="name" label="部门/角色" width="300">
          <template slot-scope="scope">{{scope.row.organization_name + ' / ' + scope.row.role_name}}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <span class="state enable" v-if="scope.row.status == 1">
              <i></i>启用
            </span>
            <span class="state disable" v-else>
              <i></i>禁用
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="leader" label="是否主管">
          <template slot-scope="scope">
            <span v-if="scope.row.leader">是</span>
            <span v-if="!scope.row.leader">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="entry_time" label="入职时间">
          <template slot-scope="scope">
            <span v-if="scope.row.entry_time">{{ scope.row.entry_time | fmtdate }}</span>
            <span v-if="!scope.row.entry_time">-</span>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.member.includes('edit')"
              class="edit"
              size="mini"
              @click="addUser(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="privilige_list.member.includes('delete')"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="dataList.total > 10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="dataList.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="dataList.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="dataList.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="抽奖" :visible.sync="dialogSmoke" top="15%" width="25%">
      <div style="text-align: center;">
        <el-select clearable placeholder="请选择抽奖人数" v-model="smokeNum">
          <el-option label="1" :value="1"></el-option>
          <el-option label="2" :value="2"></el-option>
          <el-option label="3" :value="3"></el-option>
          <el-option label="4" :value="4"></el-option>
          <el-option label="5" :value="5"></el-option>
        </el-select>
      </div>
      <div style="text-align: center;margin-top: 30px;">
        <span id="smokeName" style="display: block;min-height: 100px;"></span>
      </div>
      <div style="text-align: center;margin: 15px 0;">
        <el-button :disabled="start" type="primary" @click="smokeStart()">开始</el-button>
        <el-button :disabled="end" id="smokeEnd" type="primary" @click="smokeEnd()">停止</el-button>
      </div>
      <span style="display: block;text-align: center;" slot="footer" class="dialog-footer">
        <el-button @click="algin()">重置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import user from '../../components/global.vue'
export default {
  data () {
    return {
      start: false,
      end: false,
      smokeNum: '',
      dialogSmoke: false,
      dataList: [],
      userSite: user.userSite,
      loading: false,
      options: [],
      listParams: {
        page: 1,
        limit: 10,
        keywords: "",
        role_id: "",
        order: "id desc",
      },
      privilige_list: [],//权限列表显隐
      intervalId: null,
    };
  },
  activated () {
    // this.handleSearch();
    this.listParams
    this.getList()
  },
  mounted () {
    this.getitemId();
  },
  methods: {
    algin () {
      let Name = document.querySelector('#smokeName')
      this.smokeNum = ''
      Name.innerHTML = ''
      this.start = false
      this.end = false
    },
    //抽奖
    smoke () {
      this.dialogSmoke = true
      this.$nextTick(() => {
        let Name = document.querySelector('#smokeName')
        this.smokeNum = ''
        Name.innerHTML = ''
      })
    },
    smokeStart () {
      if (this.smokeNum == '') {
        this.$message.warning('请先填写抽奖人数')
      } else {
        let nameList = []
        let Name = document.querySelector('#smokeName')
        this.axios.get('/api/member/rand_list'
        ).then((res) => {
          nameList = res.data.map((item, index) => {
            return item.true_name
          })
        })
        this.intervalId = setInterval(function () {
          let index = parseInt(Math.random() * nameList.length)
          let randomName = nameList[index]
          Name.innerHTML = randomName
        }, 20)
        this.start = true
      }
    },
    smokeEnd () {
      clearInterval(this.intervalId)
      if (this.smokeNum) {
        let Name = document.querySelector('#smokeName')
        this.axios.get('/api/member/rand_member', { params: { num: this.smokeNum } }).then((res) => {
          Name.innerHTML = res.data.join()
          this.end = true
        })
      } else {
        this.$message.warning('请先开始')
      }
    },
    getList () {
      this.loading = true
      this.axios.get('/api/member/list', { params: { ...this.listParams } }).then((res) => {
        this.dataList = res.data
        this.loading = false
      })
    },
    handleSizeChange (val) {
      this.getList(this.listParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.listParams.page = val)
    },

    addUser (id) {
      this.$router.push({ path: "/users/add", query: { id: id } });
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/member/delete", {
            params: {
              member_id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSearch () {
      this.getList()
    },
    getitemId (val) {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.options = res.data;
        this.listParams.role_id = val;
        this.getList()
      });
    },
  },
  created () {
    this.getList()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
  },
};
</script>

<style scoped>
#smokeName {
  font-size: 40px;
  font-weight: bold;
  line-height: 100px;
}
</style>
